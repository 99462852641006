var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-card',[_c('DataTableHeader',{attrs:{"table-id":"organisation-table table","headers":_vm.headers}}),_c('v-data-table',{attrs:{"id":"organisation-table","headers":_vm.$filterHeaders(_vm.headers),"items":_vm.matchResults.leftCompanies,"sort-by":"name","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.outcomesText",fn:function(ref){
var item = ref.item;
return _vm._l((item.outcomesText),function(outcome){return _c('v-chip',{key:outcome,attrs:{"small":""}},[_vm._v(" "+_vm._s(outcome)+" ")])})}},{key:"item.challengesText",fn:function(ref){
var item = ref.item;
return _vm._l((item.challengesText),function(challenge){return _c('v-chip',{key:challenge,attrs:{"small":""}},[_vm._v(" "+_vm._s(challenge)+" ")])})}},{key:"item.techAreasText",fn:function(ref){
var item = ref.item;
return _vm._l((item.techAreasText),function(techArea){return _c('v-chip',{key:techArea,attrs:{"small":""}},[_vm._v(" "+_vm._s(techArea)+" ")])})}},{key:"expanded-item",fn:function(companyOne){return [_c('td',{staticStyle:{"background-color":"#fbfbfb","padding":"0 0 0 24px","outline":"solid 1px #cccccc"},attrs:{"colspan":companyOne.headers.length}},[_c('v-data-table',{staticStyle:{"background-color":"#fbfbfb"},attrs:{"disable-pagination":"","items-per-page":-1,"hide-default-footer":"","headers":_vm.$filterHeaders(companyOne.headers),"items":_vm.matchResults.rightCompanies,"sort-by":"name","dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(companyTwo){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":"See Matching Details"},on:{"click":function($event){return _vm.showMatchDetail(companyOne.item, companyTwo.item)}}},[_vm._v("mdi-eye")])]}},{key:"item.outcomesText",fn:function(ref){
var item = ref.item;
return _vm._l((item.outcomesText),function(outcome){return _c('v-chip',{key:outcome,attrs:{"small":""}},[_vm._v(" "+_vm._s(outcome)+" ")])})}},{key:"item.challengesText",fn:function(ref){
var item = ref.item;
return _vm._l((item.challengesText),function(challenge){return _c('v-chip',{key:challenge,attrs:{"small":""}},[_vm._v(" "+_vm._s(challenge)+" ")])})}},{key:"item.techAreasText",fn:function(ref){
var item = ref.item;
return _vm._l((item.techAreasText),function(techArea){return _c('v-chip',{key:techArea,attrs:{"small":""}},[_vm._v(" "+_vm._s(techArea)+" ")])})}}],null,true)})],1)]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center text-2xl mb-6"},[_c('span',{staticClass:"headline"},[_vm._v("Multi Criteria Matching")])])}]

export { render, staticRenderFns }