<template>
  <div>
    <div class="d-flex align-center text-2xl mb-6">
      <span class="headline">Multi Criteria Matching</span>
    </div>
    <v-card>
      <DataTableHeader table-id="organisation-table table" :headers="headers" />

      <v-data-table id="organisation-table" :headers="$filterHeaders(headers)" :items="matchResults.leftCompanies" sort-by="name" :single-expand="singleExpand" :expanded.sync="expanded" show-expand>
        <template v-slot:item.outcomesText="{ item }">
          <v-chip v-for="outcome in item.outcomesText" :key="outcome" small>
            {{ outcome }}
          </v-chip>
        </template>
        <template v-slot:item.challengesText="{ item }">
          <v-chip v-for="challenge in item.challengesText" :key="challenge" small>
            {{ challenge }}
          </v-chip>
        </template>
        <template v-slot:item.techAreasText="{ item }">
          <v-chip v-for="techArea in item.techAreasText" :key="techArea" small>
            {{ techArea }}
          </v-chip>
        </template>
        <template v-slot:expanded-item="companyOne">
          <td :colspan="companyOne.headers.length" style="background-color: #fbfbfb; padding: 0 0 0 24px; outline: solid 1px #cccccc">
            <v-data-table
              disable-pagination
              :items-per-page="-1"
              hide-default-footer
              style="background-color: #fbfbfb"
              :headers="$filterHeaders(companyOne.headers)"
              :items="matchResults.rightCompanies"
              sort-by="name"
              dense
            >
              <template v-slot:item.actions="companyTwo">
                <v-icon small class="mr-2" title="See Matching Details" @click="showMatchDetail(companyOne.item, companyTwo.item)">mdi-eye</v-icon>
              </template>
              <template v-slot:item.outcomesText="{ item }">
                <v-chip v-for="outcome in item.outcomesText" :key="outcome" small>
                  {{ outcome }}
                </v-chip>
              </template>
              <template v-slot:item.challengesText="{ item }">
                <v-chip v-for="challenge in item.challengesText" :key="challenge" small>
                  {{ challenge }}
                </v-chip>
              </template>
              <template v-slot:item.techAreasText="{ item }">
                <v-chip v-for="techArea in item.techAreasText" :key="techArea" small>
                  {{ techArea }}
                </v-chip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import DataTableHeader from '@/components/ui/DataTableHeader';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';
import MultiMatchDetailModal from '@/components/match/MultiMatchDetailModal';

export default {
  name: 'SimpleMatch',
  components: { DataTableHeader },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'City', value: 'location', hideable: true, show: true },
      { text: 'Type', value: 'companyType.name', hideable: true, show: true },
      { text: 'Outcomes', value: 'outcomesText', hideable: true, show: false },
      { text: 'Challenges', value: 'challengesText', hideable: true, show: false },
      { text: 'Inn. Areas', value: 'techAreasText', hideable: true, show: false },
      { text: 'Updated Date', value: 'updatedAt', hideable: true, show: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '100px', align: 'end' },
    ],
    searchText: '',
    leftCompanies: [],
    rightCompanies: [],
    expanded: [],
    singleExpand: false,
  }),
  computed: {
    matchResults() {
      return {
        leftCompanies: this.leftCompanies.map((c) => {
          return {
            ...c,
            outcomesText: c.outcomes.map((o) => o.name),
            challengesText: c.challenges.map((o) => o.name),
            techAreasText: c.techAreas.map((o) => o.name),
            updatedAt: dayjs(c.updated_at).format(DEFAULT_DATE_FORMAT),
          };
        }),
        rightCompanies: this.rightCompanies.map((c) => {
          return {
            ...c,
            outcomesText: c.outcomes.map((o) => o.name),
            challengesText: c.challenges.map((o) => o.name),
            techAreasText: c.techAreas.map((o) => o.name),
            updatedAt: dayjs(c.updated_at).format(DEFAULT_DATE_FORMAT),
          };
        }),
      };
    },
  },
  created() {
    const {
      leftOrganisationType,
      leftOrganisationStage,
      leftSelectedChallenges,
      leftSelectedOutcomes,
      commonSelectedTechAreas,
      rightOrganisationType,
      rightOrganisationStage,
      rightSelectedChallenges,
      rightSelectedOutcomes,
    } = this.$route.query;

    this.fetchMultiMatchData({
      leftOrganisationType,
      leftOrganisationStage,
      leftSelectedChallenges,
      leftSelectedOutcomes,
      commonSelectedTechAreas,
      rightOrganisationType,
      rightOrganisationStage,
      rightSelectedChallenges,
      rightSelectedOutcomes,
    });

    this.headers.find((h) => h.value === 'challengesText').show = !!this.$route.query?.challenge;
    this.headers.find((h) => h.value === 'outcomesText').show = !!this.$route.query?.outcome;
    this.headers.find((h) => h.value === 'techAreasText').show = !!this.$route.query?.techArea;
  },
  methods: {
    ...mapActions(['fetchMultiMatch']),
    showMatchDetail(companyOne, companyTwo) {
      this.$showModal(MultiMatchDetailModal, { companyOne, companyTwo });
    },
    async fetchMultiMatchData(params) {
      const response = await this.fetchMultiMatch({ params });
      if (response) {
        this.leftCompanies = response.leftCompanies;
        this.rightCompanies = response.rightCompanies;
      }
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>
