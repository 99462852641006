<template>
  <v-dialog v-model="dialog" max-width="900px" @keydown.esc="close">
    <v-card class="multi-match-detail-modal">
      <v-card-title>
        <span class="headline">Match Details</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card v-if="companyOne" class="p-3">
              <label>Organisation Name</label>
              <div style="font-weight: bold">{{ companyOne.name }}</div>
              <label>Organisation Type</label>
              <div>{{ companyOne.companyType.name }}</div>
              <label v-if="companyOne.companyStage">Organisation Stage</label>
              <div v-if="companyOne.companyStage">{{ companyOne.companyStage.name }}</div>
              <label>City</label>
              <div>{{ companyOne.location }}</div>
              <label>Challenges</label>
              <div>{{ arrayToText(companyOne.challengesText) }}</div>
              <label>Inn. Areas</label>
              <div>{{ arrayToText(companyOne.techAreasText) }}</div>
              <label>Outcomes</label>
              <div>{{ arrayToText(companyOne.outcomesText) }}</div>
              <label>Bio</label>
              <div>{{ companyOne.bio ? companyOne.bio : 'N/A' }}</div>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header style="padding: 0; font-size: 11px; font-weight: 500">Individuals</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table fixed-header height="300px" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">First Name</th>
                            <th class="text-left">Last Name</th>
                            <th class="text-left">Job Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in companyOneIndividuals" :key="item.individualId">
                            <td>{{ item.individual.firstName }}</td>
                            <td>{{ item.individual.lastName }}</td>
                            <td>{{ item.jobTitle }}</td>
                          </tr>
                          <tr v-if="!companyOneIndividuals.length">
                            <td colspan="3" class="text-center font-weight-medium">There is no record</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
          <v-col>
            <v-card v-if="companyTwo" class="p-3">
              <label>Organisation Name</label>
              <div style="font-weight: bold">{{ companyTwo.name }}</div>
              <label>Organisation Type</label>
              <div>{{ companyTwo.companyType.name }}</div>
              <label v-if="companyTwo.companyStage">Organisation Stage</label>
              <div v-if="companyTwo.companyStage">{{ companyTwo.companyStage.name }}</div>
              <label>City</label>
              <div>{{ companyTwo.location }}</div>
              <label>Challenges</label>
              <div>{{ arrayToText(companyTwo.challenges.map((c) => c.name)) }}</div>
              <label>Inn. Areas</label>
              <div>{{ arrayToText(companyTwo.techAreas.map((c) => c.name)) }}</div>
              <label>Outcomes</label>
              <div>{{ arrayToText(companyTwo.outcomes.map((c) => c.name)) }}</div>
              <label>Bio</label>
              <div>{{ companyTwo.bio ? companyTwo.bio : 'N/A' }}</div>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header style="padding: 0; font-size: 11px; font-weight: 500">Individuals</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table fixed-header height="300px" dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">First Name</th>
                            <th class="text-left">Last Name</th>
                            <th class="text-left">Job Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in companyTwoIndividuals" :key="item.individualId">
                            <td>{{ item.individual.firstName }}</td>
                            <td>{{ item.individual.lastName }}</td>
                            <td>{{ item.jobTitle }}</td>
                          </tr>
                          <tr v-if="!companyTwoIndividuals.length">
                            <td colspan="3" class="text-center font-weight-medium">There is no record</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-3 px-5" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MultiMatchDetailModal',
  data() {
    return {
      dialog: true,
      companyOneIndividuals: [],
      companyTwoIndividuals: [],
    };
  },
  props: {
    companyOne: Object,
    companyTwo: Object,
  },
  created() {
    this.fetchIndividualsByCompanyId(this.companyOne.id).then((response) => {
      this.companyOneIndividuals = response;
    });
    this.fetchIndividualsByCompanyId(this.companyTwo.id).then((response) => {
      this.companyTwoIndividuals = response;
    });
  },
  methods: {
    ...mapActions(['fetchIndividualsByCompanyId']),
    arrayToText(array) {
      return array.join(', ');
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
<style lang="scss">
.multi-match-detail-modal {
  label {
    font-weight: 500;
    font-size: 11px;
    line-height: 1.3;
    margin-top: 9px;
    display: block;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
